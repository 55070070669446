import { lazy } from "react";
import Loadable from "../components/Loadable";
import AuthLayout from "../layout/AuthLayout";

const AuthLogin = Loadable(
  lazy(() => import("../Container/HOME-SCREEN/Authentication/Login"))
);

const AuthRegister = Loadable(
  lazy(() => import("../Container/HOME-SCREEN/Authentication/Register"))
);
const AuthRoutes = {
  path: "auth",
  element: <AuthLayout />,
  children: [
    {
      path: "login",
      element: <AuthLogin />,
    },
    { path: "register", element: <AuthRegister /> },
  ],
};

export default AuthRoutes;
