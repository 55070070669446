// assets
import { IconDashboard } from "@tabler/icons-react";

// constant
const icons = { IconDashboard };
const VenderDashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "item",
  url: "/portal/partner/dashboard",
  icon: icons.IconDashboard,
};

export default VenderDashboard;
