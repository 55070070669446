const isProduction = process.env.NODE_ENV === "production";
const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/starvizz-react/default'
  basename: "",
  defaultPath: "/",
  fontFamily: `'Nunito'`,
  borderRadius: 12,
  apiBaseURL: isProduction
    ? `https://skilltre.starvizz.com/api`
    : `http://127.0.0.1:8000/api`,
};
export default config;
