import { lazy } from "react";
import Loadable from "../components/Loadable";
import PortalLayout from "../layout/PortalLayout";

const VenderDashboard = Loadable(
  lazy(() =>
    import("../Container/VENDER-PORTAL/VenderDashboard/VenderDashboard")
  )
);
const Documentation = Loadable(
  lazy(() => import("../Container/VENDER-PORTAL/Documentation"))
);
const VenderPlayGround = Loadable(
  lazy(() => import("../Container/VENDER-PORTAL/playground/VenderPlayGround"))
);
const VenderViewPackagesList = Loadable(
  lazy(() =>
    import("../Container/VENDER-PORTAL/packages/VenderViewPackagesList")
  )
);
const FreePackageSearchList = Loadable(
  lazy(() =>
    import("../Container/VENDER-PORTAL/playground/FreePackageSearchList")
  )
);
const ActivatedPackagesList = Loadable(
  lazy(() =>
    import(
      "../Container/VENDER-PORTAL/packages/ActivatedPackagesList/ActivatedPackagesList"
    )
  )
);
const ActivePackageSearchList = Loadable(
  lazy(() =>
    import("../Container/VENDER-PORTAL/packages/ActivePackSearchHistory")
  )
);

const PartnerRoutes = {
  path: "portal/partner",
  element: <PortalLayout />,
  children: [
    {
      path: "dashboard",
      element: <VenderDashboard />,
    },
    {
      path: "Documentation",
      element: <Documentation />,
    },
    {
      path: "playground",
      children: [
        { path: "", element: <VenderPlayGround /> },
        { path: "search-history", element: <FreePackageSearchList /> },
      ],
    },
    {
      path: "packages-list",
      element: <VenderViewPackagesList />,
    },
    {
      path: "all-activated-packages",
      children: [
        { path: "", element: <ActivatedPackagesList /> },
        {
          path: ":UserPackUniqueId/search-history",
          element: <ActivePackageSearchList />,
        },
      ],
    },
  ],
};
export default PartnerRoutes;
