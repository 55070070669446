// assets
import { IconFileCertificate } from "@tabler/icons-react";

// constant
const icons = { IconFileCertificate };
const Documentation_menu = {
  id: "Documentation",
  title: "Documentation",
  type: "item",
  url: "/portal/partner/Documentation",
  icon: icons.IconFileCertificate,
};

export default Documentation_menu;
