// vender - playground - menu;
// assets
import { IconChartHistogram } from "@tabler/icons-react";

// constant
const icons = { IconChartHistogram };
const vender_purchase_history = {
  id: "vender_purchase_history",
  title: "Purchase History",
  type: "item",
  url: "/portal/partner/all-activated-packages",
  icon: icons.IconChartHistogram,
};

export default vender_purchase_history;
