// assets
import { IconUserShield } from "@tabler/icons-react";

// constant
const icons = {
  IconUserShield,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const SubAdmin = {
  id: "Sub Admin",
  title: "Company Users",
  type: "item",
  url: "/portal/admin/sub-admins",
  icon: icons.IconUserShield,
};

export default SubAdmin;
