// assets
import { IconChartHistogram } from "@tabler/icons-react";

// constant
const icons = {
  IconChartHistogram,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const sales_history = {
  id: "Sales History",
  title: "Sales History",
  type: "item",
  url: "/portal/admin/sales-history",
  icon: icons.IconChartHistogram,
};

export default sales_history;
