import React from "react";
import {
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconChevronRight,
  IconMail,
  IconDeviceMobile,
} from "@tabler/icons-react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const linksData = [
    {
      linkText: "Home",
      pageLink: "/",
    },
    {
      linkText: "About Us",
      pageLink: "/about-us",
    },
    {
      linkText: "Contact Us",
      pageLink: "/contact-us",
    },
    {
      linkText: "Login",
      pageLink: "/login",
    },

    {
      linkText: "Documentation",
      pageLink: "/",
    },

    {
      linkText: "Blogs",
      pageLink: "/",
    },
  ];
  const links = linksData.slice(0, 4);
  const useFullLinks = linksData.slice(4);
  return (
    <>
      <Grid className="pt-80 pb-80 footer-section">
        <Grid className="container" container spacing={3}>
          {/* Column 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h1" gutterBottom className="footer-header">
              Starvizz
            </Typography>
            <Typography
              mt={3}
              variant="body2"
              component="p"
              color="textSecondary"
              className="foot-text"
            >
              We are a company dedicated to providing excellent services and
              solutions to our customers. Our mission is to deliver top-quality
              products and exceptional customer support.
            </Typography>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} sm={6} md={2} className="foot-links-col">
            <Typography
              variant="h3"
              gutterBottom
              className="footer-header"
              pl={3}
            >
              Links
            </Typography>
            <List>
              {links.map((link, i) => (
                <ListItem key={i} className="foot-link-li">
                  <ListItemIcon>
                    <IconChevronRight size={20} />
                  </ListItemIcon>
                  <Link
                    href={link.pageLink}
                    variant="body2"
                    color="textSecondary"
                    className="foot-link"
                  >
                    {link.linkText}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Column 3 */}
          <Grid item xs={12} sm={6} md={3} className="foot-links-col">
            <Typography
              variant="h3"
              gutterBottom
              className="footer-header"
              pl={3}
            >
              Useful Links
            </Typography>
            <List>
              {useFullLinks.map((link, i) => (
                <ListItem key={i} className="foot-link-li">
                  <ListItemIcon>
                    <IconChevronRight size={20} />
                  </ListItemIcon>
                  <Link
                    href={link.pageLink}
                    variant="body2"
                    color="textSecondary"
                    className="foot-link"
                  >
                    {link.linkText}
                  </Link>
                </ListItem>
              ))}
            </List>
            <Grid>
              <Typography
                variant="h4"
                gutterBottom
                className="footer-header"
                pl={3}
              >
                Follow Us
              </Typography>
              <List className="d-flex social-links-ul">
                <ListItem>
                  <Link href="#" variant="body2">
                    <IconBrandFacebook />
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#" variant="body2">
                    <IconBrandTwitter />
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#" variant="body2">
                    <IconBrandLinkedin />
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          {/* Column 4 */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" gutterBottom className="footer-header">
              Contact Us
            </Typography>
            <Typography mt={3}>
              <Link
                href="tel:+919994147766"
                variant="body2"
                color="textSecondary"
                className="foot-link d-flex align-items-center"
              >
                <IconDeviceMobile size={20} /> &nbsp; +91 99941 47766
              </Link>
            </Typography>
            <Typography mt={2}>
              <Link
                href="mailto:starvizz@gmail.com"
                variant="body2"
                color="textSecondary"
                className="foot-link d-flex align-items-center"
              >
                <IconMail size={20} />
                &nbsp; starvizz@gmail.com
              </Link>
            </Typography>

            <Typography
              mt={1}
              variant="body2"
              color="textSecondary"
              className="foot-contact"
            >
              4th Cross, VOC Nagar,
              <br />
              Basthi,Hosur-635109,
              <br />
              Tamil Nadu, India
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid className="copyright-section" boxShadow={3}>
        <Grid
          className="container d-flex align-items-center "
          container
          spacing={3}
        >
          <Grid item xs={12} sm={6} md={6} className="copy-right-text">
            © {currentYear}. All Rights Reserved Design And Maintained By
            Starvizz
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid className="d-flex" justifyContent="end">
              <Typography>
                <Link
                  href="#"
                  variant="body2"
                  className="copy-right-link"
                  pr={1}
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography variant="body2" className="copy-right-link" pr={1}>
                |
              </Typography>
              <Typography>
                <Link href="#" variant="body2" className="copy-right-link">
                  Terms And Conditions
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
