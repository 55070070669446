// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Chip, Drawer, Stack, useMediaQuery} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import { drawerWidth } from "../../store/constant";
import LogoSection from "../LogoSection/LogoSection";
import MenuList from "./MenuList/MenuList";
import axiosInstance from "src/utils/CustomAxios";
import { useAuth } from "src/context/AuthContext";
import { API_AUTH_LOGOUT } from "src/utils/http/apiPaths";
import { useNavigate } from "react-router-dom";
// hook
// import LogoutNavItem from "./MenuList/NavItem/LogoutNavItem";
import { GetAuthRole } from "src/hooks/useAuthRole";

// ==============================|| SIDEBAR DRAWER ||============================== //
const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const AuthRole = GetAuthRole();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      const res = await axiosInstance.post(API_AUTH_LOGOUT, {
        refresh_token: localStorage.getItem("refreshToken"), // Pass the refresh token in the request body
      });
      const { status } = res;
      if (status === 200) {
        // Perform any client-side logout actions
        logout();
        navigate("/auth/login");
      }
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle error
    }
  };
  const drawer = (
    <>
      <Box >
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView className="sidebar-lay">
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <MenuList AuthRole={AuthRole} />
          {/* <Button onClick={handleLogout}>Logout</Button> */}
          {/* <LogoutNavItem logout={handleLogout} /> */}
          <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            <Chip
              label={process.env.REACT_APP_VERSION}
              disabled
              chipcolor="secondary"
              size="small"
              sx={{ cursor: "pointer" }}
            />
          </Stack>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />

          <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            <Chip
              label={process.env.REACT_APP_VERSION}
              disabled
              chipcolor="secondary"
              size="small"
              sx={{ cursor: "pointer" }}
            />
          </Stack>
        </Box>
      </MobileView>
    </>
  );
  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      <Drawer
        className={drawerOpen ? "maximize-sidebar" : "minimal-sidebar"}
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            // [theme.breakpoints.up("md")]: {
            //   top: "88px",
            // },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
