import PortalLayout from "../layout/PortalLayout";
import Loadable from "../components/Loadable";
import { lazy } from "react";
import { useAuthRole } from "src/hooks/useAuthRole";

const SkillsList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/skills/SkillsList"))
);
const SkillCreate = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/skills/skill_create/SkillCreate")
  )
);
const SkillEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/skills/skill_edit/SkillEdit"))
);
const UploadsStatus = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/skills/UploadsStatus"))
);
const SkillWithRelatedSKillsEdit = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/skills/skill_edit/SkillWithRelationEdit")
  )
);
const SkillRelationEdit = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/SkillRelations/SkillRelationEdit")
  )
);

const SkillRelationList = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/SkillRelations/SkillRelationList")
  )
);
const AdminDashboard = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/Dashboard/AdminDashboard"))
);
const ClientList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/Clients/ClientsList"))
);
const ClientCreate = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/Clients/ClientCreate"))
);
const ClientEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/Clients/ClientEdit"))
);
const PlayGroundSearchHistory = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/Clients/PlayGroundSearchHistory")
  )
);

// const SearchSkillPlayground = Loadable(
//   lazy(() =>
//     import("../Container/ADMIN-PORTAL/Playground/SearchToViewPlayGround")
//   )
// );

// sub admins
const SubAdminList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/sub_admins/SubAdminList"))
);
const SubAdminCreate = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/sub_admins/SubAdminCreate"))
);
const SubAdminEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/sub_admins/SubAdminEdit"))
);

//subscribe packages
const PackageList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/Packages/PackageList"))
);
const PackageCreate = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/Packages/PackageCreate"))
);
const PackageEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/Packages/PackageEdit"))
);
// pay as you go crud
const PayAsYouGoList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/PayAsYouGo/PayAsYouGoList"))
);
const PayAsYouGoCreate = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/PayAsYouGo/PayAsYouGoCreate"))
);
const PayAsYouGoEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/PayAsYouGo/PayAsYouGoEdit"))
);
//additional talk time pack crud
const AdditionalPackList = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/AdditionalPack/AdditionalPackList")
  )
);
const AdditionalPackCreate = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/AdditionalPack/AdditionalPackCreate")
  )
);
const AdditionalPackEdit = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/AdditionalPack/AdditionalPackEdit")
  )
);
// free package crud
const FreePackageList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/FreePackage/FreePackageList"))
);
const FreePackageCreate = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/FreePackage/FreePackageCreate"))
);
const FreePackageEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/FreePackage/FreePackageEdit"))
);
// currencies package crud
const CurrencyList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/currencies/CurrencyList"))
);
const CurrencyCreate = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/currencies/CurrencyCreate"))
);
const CurrencyEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/currencies/CurrencyEdit"))
);
// categories  crud
const CategoryList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/categories/CategoryList"))
);
const CategoryCreate = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/categories/CategoryCreate"))
);
const CategoryEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/categories/CategoryEdit"))
);
const SalesHistory = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/SalesHistory/SalesHistory"))
);
const DefaultSetup = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/DefaultSetup/DefaultSetup"))
);
const AdminRoutes = () => {
  // Function to conditionally include "sub-admins" section based on user role
  function useSubAdminRoutes() {
    const isAdmin = useAuthRole(0); // Assuming 1 is the role ID for sub-admins
    if (isAdmin) {
      return {
        path: "sub-admins",
        children: [
          { path: "", element: <SubAdminList /> },
          { path: "create", element: <SubAdminCreate /> },
          { path: "edit/:SubAdminId", element: <SubAdminEdit /> },
        ],
      };
    }
    return null; // Return null if user is not a sub-admin
  }
  // Call the function to get the sub-admin routes
  const subAdminRoutes = useSubAdminRoutes();
  return {
    path: "portal/admin",
    element: <PortalLayout />,
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "skills",
        children: [
          { path: "", element: <SkillsList /> },
          { path: "create", element: <SkillCreate /> },
          { path: "edit/:SkillId", element: <SkillEdit /> },
          {
            path: "skill-update-with-related-skills/:SkillId/:RelationId",
            element: <SkillWithRelatedSKillsEdit />,
          },
          {
            path: "skill-relation-edit/:SkillRelationId",
            element: <SkillRelationEdit />,
          },
          // {
          //   path: "playground",
          //   element: <SkillPlayground />,
          // },
          // {
          //   path: "playground",
          //   element: <SearchSkillPlayground />,
          // },
          {
            path: "upload-status",
            element: <UploadsStatus />,
          },
        ],
      },
      {
        path: "skill-relations",
        children: [{ path: "", element: <SkillRelationList /> }],
      },
      {
        path: "clients",
        children: [
          { path: "", element: <ClientList /> },
          { path: "create", element: <ClientCreate /> },
          { path: "edit/:ClientId", element: <ClientEdit /> },
          {
            path: "play-ground-history/:FreePackUniqueId",
            element: <PlayGroundSearchHistory />,
          },
        ],
      },
      {
        path: "packages",
        children: [
          { path: "", element: <PackageList /> },
          { path: "create", element: <PackageCreate /> },
          { path: "edit/:PackageId", element: <PackageEdit /> },
        ],
      },
      {
        path: "pay-as-you-go",
        children: [
          { path: "", element: <PayAsYouGoList /> },
          { path: "create", element: <PayAsYouGoCreate /> },
          { path: "edit/:PackageId", element: <PayAsYouGoEdit /> },
        ],
      },
      {
        path: "additional-talk-time-pack",
        children: [
          { path: "", element: <AdditionalPackList /> },
          { path: "create", element: <AdditionalPackCreate /> },
          { path: "edit/:PackageId", element: <AdditionalPackEdit /> },
        ],
      },
      {
        path: "free-package",
        children: [
          { path: "", element: <FreePackageList /> },
          { path: "create", element: <FreePackageCreate /> },
          { path: "edit/:PackageId", element: <FreePackageEdit /> },
        ],
      },
      {
        path: "currencies",
        children: [
          { path: "", element: <CurrencyList /> },
          { path: "create", element: <CurrencyCreate /> },
          { path: "edit/:currencyId", element: <CurrencyEdit /> },
        ],
      },
      {
        path: "categories",
        children: [
          { path: "", element: <CategoryList /> },
          { path: "create", element: <CategoryCreate /> },
          { path: "edit/:categoryId", element: <CategoryEdit /> },
        ],
      },
      {
        path: "sales-history",
        children: [{ path: "", element: <SalesHistory /> }],
      },
      {
        path: "default-setup",
        children: [{ path: "", element: <DefaultSetup /> }],
      },

      // Include the sub-admin routes if available
      subAdminRoutes,
    ].filter(Boolean),
  };
};

export default AdminRoutes;
