// assets
import {
  IconSettingsPlus,
  IconSettings,
  IconCurrencyDollar,
  IconCategory2,
} from "@tabler/icons-react";

// constant
const icons = {
  IconSettingsPlus,
  IconSettings,
  IconCurrencyDollar,
  IconCategory2,
};

const defaultMenu = {
  id: "setup",
  title: "Setup",
  caption: "Default Setup",
  type: "collapse",
  icon: icons.IconSettings,
  children: [
    {
      id: "categories",
      title: "Domains",
      type: "item",
      url: "/portal/admin/categories",
      icon: icons.IconCategory2,
    },
    {
      id: "currencies",
      title: "Currencies",
      type: "item",
      url: "/portal/admin/currencies",
      icon: icons.IconCurrencyDollar,
    },
    {
      id: "default_setup",
      title: "Limitation Setup",
      type: "item",
      url: `/portal/admin/default-setup`,
      icon: icons.IconSettingsPlus,
    },
  ],
};

export default defaultMenu;
