import React from "react";
import { Outlet } from "react-router-dom";

const PlayGroundLayout = () => {
    return (
      <>
        <Outlet />
      </>
    );
};

export default PlayGroundLayout;
