export const API_AUTH_LOGIN = "/v1/auth/login/";
export const API_AUTH_LOGOUT = "/v1/auth/logout/";
export const API_COMPANY_USER_PLAYGROUND_SEARCH =
  "/v1/skills/admin-search/?skill=";
export const API_VENDER_PLAYGROUND_SEARCH =
  "v1/skills/playground-search/?skills=";
export const API_VENDER_FREE_PACK_DETAIL =
  "v1/skills/user-free-package-detail/";
export const API_VENDER_FREE_PACK_SEARCH_HISTORY =
  "v1/packages/user-free-package/search-history";
export const API_VENDER_PACK_SEARCH_HISTORY =
  "v1/packages/user-package/search-history/";
export const API_VENDER_FREE_PACK_SEARCH_HISTORY_VIEW_BY_ADMIN =
  "v1/packages/user-free-package/search-history-view-by-admin/";
export const API_VENDER_VIEW_PACKAGES_LIST =
  "/v1/clients/available-packages-list/";
export const API_VENDER_ACTIVATE_BASIC_PACKAGE =
  "/v1/packages/activate-basic-package/";
export const API_VENDER_ACTIVATE_PAY_AS_YOU_GO_PACKAGE =
  "/v1/packages/activate-pay-as-you-go-package/";
export const API_VENDER_ACTIVATE_ADDITIONAL_TALK_TIME_PACKAGE =
  "/v1/packages/activate_additional_talk_time_pack/";
// clients skills api links
export const API_SKILLS_LIST = "/v1/skills/list";
export const API_CREATE_SKILL = "/v1/skills/create";
export const API_EDIT_SKILL = "/v1/skills/retrieve/";
export const API_UPDATE_SKILL = "/v1/skills/update/";
export const API_DISABLE_SKILL = "/v1/skills/delete/";

// Upload Status API
export const API_SKILL_FILE_UPLOADS =
  "/v1/skills/file_upload_record_list_view/";
export const API_SKILL_FILE_UPLOAD_SPECIFIC_DETAILS = "/v1/skills/task-status/";
export const API_SKILL_FILE_GENERATE_CSV =
  "/v1/skills/file_upload_successful_data_export/";

// skill with relation api
export const API_EDIT_SKILL_WITH_RELATIONS =
  "/v1/skills/skill-with-relations-retrieve/";
export const API_EDIT_SKILL_WITH_RELATIONS_CSV_EXPORT =
  "/v1/skills/skill-with-relations-retrieve-export/";
export const API_SKILL_WITH_RELATIONS_UPDATE =
  "/v1/skills/skill-with-relations-update/";
export const API_EDIT_SKILL_RELATIONS_CSV_IMPORT =
  "/v1/skills/skill-based-relations-import/";

export const API_SKILL_RELATION_DISABLE = "/v1/skills/skill-relation-delete/";
export const API_SKILL_BULK_UPLOAD = "/v1/skills/skill_relation_bulk_import/";
export const API_SEARCH_AUTO_SKILLS = "/v1/skills/search-auto";
export const API_SKILLS_CREATED_EXPORT =
  "/v1/skills/export-skills-newly-created/";

// skill relations api links
export const API_SKILLS_RELATIONS_LIST = "/v1/skills/skill-relations/list";
export const API_EDIT_SKILL_RELATION = "/v1/skills/skill-relations/retrieve/";
export const API_UPDATE_SKILL_RELATION = "/v1/skills/skill-relations/update/";
// clients crud api links
export const API_CLIENT_LIST = "/v1/clients/list";
export const API_CLIENT_CREATE = "/v1/clients/create";
export const API_CLIENT_EDIT = "/v1/clients/edit/";
export const API_CLIENT_SELF_DETAILS = "/v1/clients/self-details/";
export const API_CLIENT_CURRENT_PACKAGE =
  "/v1/clients/current-package-details/";
// ##################################

export const API_CLIENT_FREE_PACK_UPDATE_CALLS =
  "v1/packages/user-free-package/";
export const API_CLIENT_FREE_PACK_UPDATE_BACKUP_DURATION =
  "v1/packages/user-free-package-backup-update/";
export const API_CLIENT_FREE_PACK_UPDATE_RESPONSE_PERCENTAGE =
  "v1/packages/user-free-package-res-percentage-update/";

export const API_CLIENT_UPDATE = "/v1/clients/update/";
// sub admin crud api links
export const API_SUB_ADMINS_LIST = "/v1/sub-admins/list";
export const API_SUB_ADMINS_CREATE = "/v1/sub-admins/create";
export const API_SUB_ADMINS_EDIT = "/v1/sub-admins/edit/";
export const API_SUB_ADMINS_SELF_DETAILS = "/v1/sub-admins/self-details/";
export const API_SUB_ADMINS_UPDATE = "/v1/sub-admins/update/";
// package crud api links
export const API_PACKAGES_LIST = "/v1/packages/basic_packages/list";
export const API_PACKAGE_CREATE = "/v1/packages/basic_packages/create";
export const API_PACKAGE_EDIT = "/v1/packages/basic_packages/edit/";
export const API_PACKAGE_UPDATE = "/v1/packages/basic_packages/update/";
export const API_PACKAGE_DELETE = "/v1/packages/basic_packages/";
// pay as you go crud api links
export const API_PAY_AS_YOU_GO_PLANS_LIST =
  "/v1/packages/pay_as_you_go_package/list";
export const API_PAY_AS_YOU_GO_PLAN_CREATE =
  "/v1/packages/pay_as_you_go_package/create";
export const API_PAY_AS_YOU_GO_PLAN_EDIT =
  "/v1/packages/pay_as_you_go_package/edit/";
export const API_PAY_AS_YOU_GO_PLAN_UPDATE =
  "/v1/packages/pay_as_you_go_package/update/";
export const API_PAY_AS_YOU_GOP_PLAN_DELETE =
  "/v1/packages/pay_as_you_go_package/";
// additional talk time package crud api links
export const API_ADDITIONAL_TOP_UP_PACKAGES_LIST =
  "/v1/packages/additional_talk_time_packs/list";
export const API_ADDITIONAL_TOP_UP_PACKAGE_CREATE =
  "/v1/packages/additional_talk_time_packs/create";
export const API_ADDITIONAL_TOP_UP_PACKAGE_EDIT =
  "/v1/packages/additional_talk_time_packs/edit/";
export const API_ADDITIONAL_TOP_UP_PACKAGE_UPDATE =
  "/v1/packages/additional_talk_time_packs/update/";
export const API_ADDITIONAL_TOP_UP_PACKAGE_DELETE =
  "/v1/packages/additional_talk_time_packs/";
// free package crud api links
export const API_FREE_PACKAGES_LIST = "/v1/packages/free_packages/list";
export const API_FREE_PACKAGE_CREATE = "/v1/packages/free_packages/create";
export const API_FREE_PACKAGE_EDIT = "/v1/packages/free_packages/edit/";
export const API_FREE_PACKAGE_UPDATE = "/v1/packages/free_packages/update/";
export const API_FREE_PACKAGE_DELETE = "/v1/packages/free_packages/";
// currencies crud api links
export const API_CURRENCIES_LIST = "/v1/currencies/list";
export const API_CURRENCIES_CREATE = "/v1/currencies/create";
export const API_CURRENCIES_EDIT = "/v1/currencies/edit/";
export const API_CURRENCIES_UPDATE = "/v1/currencies/update/";
export const API_CURRENCIES_DELETE = "/v1/currencies/";
// categories crud api links
export const API_CATEGORIES_LIST = "/v1/categories/list";
export const API_CATEGORIES_CREATE = "/v1/categories/create";
export const API_CATEGORIES_EDIT = "/v1/categories/edit/";
export const API_CATEGORIES_UPDATE = "/v1/categories/update/";
export const API_CATEGORIES_DELETE = "/v1/categories/";
export const API_SEARCH_AUTO_CATEGORIES = "/v1/categories/search-auto";

export const API_SKILL_RELATION_UNIQUE_VALIDATION =
  "/v1/skills/skill_relation_unique_validate_view/";
export const API_DEFAULT_SETUP = "/v1/default-setup/";

export const API_USER_PURCHASED_PACKAGES_HISTORY =
  "/v1/packages/purchased-packages-history/";
export const API_ADMIN_VIEW_USER_PURCHASED_PACKAGES_HISTORY =
  "/v1/packages/admin_user_activated_packages_history_view/";
export const API_ADMIN_PANEL = "/v1/admin_panel/";
// later remove below for frontend and backend

export const API_INVOICE_UPLOAD = "/v1/invoice-reader/invoice_import/";
export const API_INVOICE_LIST = "/v1/invoice-reader/list";
// ##################################################
export const API_TERM_AUTO_SUGGEST = "/v1/skills/playground-skill-suggestions";
export const API_PLAYGROUND_GROUP_OF_SEARCH_HISTORY = "/v1/skills/playground-range-search-history";
