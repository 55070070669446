import PortalLayout from "../layout/PortalLayout";
import Loadable from "../components/Loadable";
import { lazy } from "react";
const SkillsList = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/skills/SkillsList"))
);
const SkillCreate = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/skills/skill_create/SkillCreate")
  )
);
const SkillEdit = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/skills/skill_edit/SkillEdit"))
);
const UploadsStatus = Loadable(
  lazy(() => import("../Container/ADMIN-PORTAL/skills/UploadsStatus"))
);
const SkillWithRelatedSKillsEdit = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/skills/skill_edit/SkillWithRelationEdit")
  )
);
const SkillRelationEdit = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/SkillRelations/SkillRelationEdit")
  )
);

const SkillRelationList = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/SkillRelations/SkillRelationList")
  )
);
const CompanyUserRoutes = {
  path: "portal/companyUser",
  element: <PortalLayout />,
  children: [
    {
      path: "skills",
      children: [
        { path: "", element: <SkillsList /> },
        { path: "create", element: <SkillCreate /> },
        { path: "edit/:SkillId", element: <SkillEdit /> },
        {
          path: "skill-update-with-related-skills/:SkillId/:RelationId",
          element: <SkillWithRelatedSKillsEdit />,
        },
        {
          path: "skill-relation-edit/:SkillRelationId",
          element: <SkillRelationEdit />,
        },
        {
          path: "upload-status",
          element: <UploadsStatus />,
        },
      ],
    },
    {
      path: "skill-relations",
      children: [{ path: "", element: <SkillRelationList /> }],
    },
  ],
};

export default CompanyUserRoutes;
