import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "src/website/layout/MainLayout";

const HomePage = Loadable(lazy(() => import("../website/pages/homepage")));
const AboutPage = Loadable(lazy(() => import("../website/pages/about-us")));
const ContactPage = Loadable(lazy(() => import("../website/pages/contact-us")));
const InvoiceReader = Loadable(
  lazy(() => import("../website/pages/InvoiceReader"))
);

const PublicRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/about-us",
      element: <AboutPage />,
    },
    {
      path: "/contact-us",
      element: <ContactPage />,
    },
    {
      path: "/invoice-reader",
      element: <InvoiceReader />,
    },
  ],
};

export default PublicRoutes;
