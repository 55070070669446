// vender - playground - menu;
// assets
import { IconSearch, IconFileTime, IconAffiliate } from "@tabler/icons-react";

// constant
const icons = { IconSearch, IconFileTime, IconAffiliate };
const VenderPlayGroundMenu = {
  id: "playground",
  title: "Playground",
  type: "collapse",
  icon: icons.IconSearch,
  children: [
    {
      id: "playground",
      title: "Playground",
      type: "item",
      url: "/playground",
      icon: icons.IconAffiliate,
    },
    {
      id: "playground search history",
      title: "Search History",
      type: "item",
      url: "/portal/partner/playground/search-history",
      icon: icons.IconFileTime,
    },
  ],
};

export default VenderPlayGroundMenu;
