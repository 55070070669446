// assets
import {
  IconBook,
  IconHierarchy,
  IconCode,
  IconSearch,
  IconUpload,
  IconBraille,
} from "@tabler/icons-react";

// constant
const icons = {
  IconBook,
  IconHierarchy,
  IconCode,
  IconSearch,
  IconUpload,
  IconBraille,
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const BASE_URLS = {
  0: "/portal/admin",
  4: "/portal/companyUser",
};
const createSkillsMenu = (role) => {
  const baseURL = BASE_URLS[role] || BASE_URLS.admin;

  return {
    id: "Skills",
    title: "Skills",
    caption: "Skills With Relations",
    type: "collapse",
    icon: icons.IconBraille,
    children: [
      {
        id: "skills",
        title: "Skills",
        type: "item",
        url: `${baseURL}/skills`,
        icon: icons.IconBook,
      },
      {
        id: "skill_relations",
        title: "Skill Relations",
        type: "item",
        url: `${baseURL}/skill-relations`,
        icon: icons.IconHierarchy,
      },
      // {
      //   id: "playground",
      //   title: "API Playground",
      //   type: "item",
      //   url: "${baseURL}/skills/playground",
      //   icon: icons.IconCode,
      // },
      {
        id: "search_playground",
        title: "Search Skill Playground",
        type: "item",
        url: `/playground`,
        icon: icons.IconSearch,
      },
      {
        id: "upload_Status",
        title: "Upload Status",
        type: "item",
        url: `${baseURL}/skills/upload-status`,
        icon: icons.IconUpload,
      },
    ],
  };
};

export default createSkillsMenu;
