import styled from "@emotion/styled";
import { ListItem, ListItemText, Typography } from "@mui/material";

export const StyledText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#000",
  marginBottom: theme.spacing(2),
}));

export const StyledSubText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#003b72",
  marginBottom: theme.spacing(2),
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: "1px solid lightgrey",
  padding: "10px 0px 0px 0px !important",
  justifyContent: "center !important",
}));
