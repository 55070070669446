import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "src/website/layout/MainLayout";
import PlayGroundLayout from "src/layout/PlayGroundLayout";

const SearchSkillPlayground = Loadable(
  lazy(() =>
    import("../Container/ADMIN-PORTAL/Playground/SearchToViewPlayGround")
  )
);

const PlaygroundRoute = {
  path: "/",
  element: <PlayGroundLayout />,
  children: [
    {
      path: "/playground",
      element: <SearchSkillPlayground />,
    },
  ],
};

export default PlaygroundRoute;
