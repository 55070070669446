// vender - playground - menu;
// assets
import { IconPackages } from "@tabler/icons-react";

// constant
const icons = { IconPackages };
const vender_packages_menu = {
  id: "vender_packages_list",
  title: "Packages",
  type: "item",
  url: "/portal/partner/packages-list",
  icon: icons.IconPackages,
};

export default vender_packages_menu;
