import { IconUsers } from "@tabler/icons-react";

// constant
const icons = {
  IconUsers,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const clients = {
  id: "Clients",
  title: "Clients",
  type: "item",
  url: "/portal/admin/clients",
  icon: icons.IconUsers,
};

export default clients;
