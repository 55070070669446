import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import User1 from "src/assets/user-round.svg";

// assets
import {
  IconLogout,
  IconSettings,
} from "@tabler/icons-react";
import { Popover } from "@mui/material";
import axiosInstance from "src/utils/CustomAxios";
import { useAuth } from "src/context/AuthContext";
import { API_AUTH_LOGOUT } from "src/utils/http/apiPaths";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      const res = await axiosInstance.post(API_AUTH_LOGOUT, {
        refresh_token: localStorage.getItem("refreshToken"), // Pass the refresh token in the request body
      });
      const { status } = res;
      if (status === 200) {
        // Perform any client-side logout actions
        logout();
        navigate("/auth/login");
      }
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle error
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popover
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        role={undefined}
        transition
        disablePortal
      >
        <Paper className="profile-tab">
          <ClickAwayListener onClickAway={handleClose}>
            <Card
              border={false}
              elevation={16}
              content={false}
              boxShadow
              shadow={theme.shadows[16]}
            >
              <Box sx={{ p: 2, pb: 0 }}>
                <Stack sx={{ pb: 2 }}>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="h4">Hello,</Typography>
                    <Typography
                      component="span"
                      variant="h4"
                      sx={{ fontWeight: 400 }}
                    >
                      Johne Doe
                    </Typography>
                  </Stack>
                  {/* <Typography variant="subtitle2">Project Admin</Typography> */}
                </Stack>                
              </Box>

              <Box>
                <Divider />

                <List
                  component="nav"
                  sx={{
                    padding: 0,
                    width: "100%",
                    maxWidth: 250,
                    minWidth: 250,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "10px",
                    [theme.breakpoints.down("md")]: {
                      minWidth: "100%",
                    },
                    "& .MuiListItemButton-root": {
                      mt: 0.5,
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: `${customization.borderRadius}px`,
                    }}
                    selected={selectedIndex === 4}
                    onClick={handleLogout}
                  >
                    <ListItemIcon>
                      <IconLogout stroke={1.5} size="1.3rem" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="body2">Logout</Typography>}
                    />
                  </ListItemButton>
                </List>
              </Box>
            </Card>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  );
};

export default ProfileSection;
