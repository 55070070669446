import React, { useState, useEffect } from "react";
import { IconButton, CircularProgress } from "@mui/material";
import { IconArrowUp } from "@tabler/icons-react";

const BackToTopButton = () => {
  const [showProgress, setShowProgress] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const winHeight = window.innerHeight;
      const scrollRatio = (scrollTop / (docHeight - winHeight)) * 100;

      setScrollPercent(scrollRatio);
      setShowProgress(scrollTop > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <IconButton
      onClick={scrollToTop}
      className="back-to-top"
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        display: showProgress ? "block" : "none",
        border: `2px solid ${scrollPercent > 100 ? "#000" : "#000"}`,
        borderRadius: "50%",
        background: "lightgrey",
        "&:hover": {
          borderColor: "#000",
        },
        transition: "border 0.3s",
      }}
    >
      <CircularProgress
        variant="determinate"
        value={scrollPercent}
        size={46}
        thickness={2}
        sx={{ position: "absolute", color: "#2196f3" }}
      />
      <IconArrowUp />
    </IconButton>
  );
};

export default BackToTopButton;
