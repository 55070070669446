import styled from "@emotion/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";

const LightTooltip = styled(({ isVisible, className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: `${className} custom-tooltip` }}
    arrow
    placement="right"
    leaveDelay={300}
  />
))(({ theme, isVisible }) => ({
  display: isVisible ? "block" : "none",
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    border: "1px solid lightgrey",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    // border: "1px solid red",
  },
}));

const CustomTooltip = ({ title, children }) => {
  // Check if the parent has the 'miniml-sidebar' class

  const parentHasMinimlSidebar = document.querySelector(".minimal-sidebar");
  return (
    <LightTooltip title={title} isVisible={parentHasMinimlSidebar}>
      {children}
    </LightTooltip>
  );
};

export default CustomTooltip;
