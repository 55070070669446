// assets
import { IconBox, IconCash, IconGift,IconPackages } from "@tabler/icons-react";

// constant
const icons = {
  IconBox,
  IconCash,
  IconGift,
  IconPackages,
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const packages = {
  id: "Packages",
  title: "Packages",
  type: "collapse",
  icon: icons.IconPackages,
  children: [
    {
      id: "Packages",
      title: "Packages",
      type: "item",
      url: "/portal/admin/packages",
      icon: icons.IconBox,
    },
    {
      id: "Pay as You Go",
      title: "Pay as You Go",
      type: "item",
      url: "/portal/admin/pay-as-you-go",
      icon: icons.IconCash,
    },
    {
      id: "TalkTime",
      title: "Additional Packs",
      type: "item",
      url: "/portal/admin/additional-talk-time-pack",
      icon: icons.IconCash,
    },
    {
      id: "Free Package",
      title: "Free Package",
      type: "item",
      url: "/portal/admin/free-package",
      icon: icons.IconGift,
    },
  ],
};

export default packages;
