import { Navigate, useRoutes } from "react-router-dom";
import AuthRoutes from "../ROUTE-PATHS/AuthRoutes";
import AdminRoutes from "../ROUTE-PATHS/adminRoutes";
import PartnerRoutes from "../ROUTE-PATHS/partnerRoutes";
import CompanyUserRoutes from "../ROUTE-PATHS/CompanyUserRoutes";
import { useAuthRole } from "../hooks/useAuthRole";
import PublicRoutes from "../ROUTE-PATHS/PublicRoutes";
import dataAnalyzeUserRoutes from "../ROUTE-PATHS/dataAnalyzeUserRoutes";
import PlaygroundRoute from "src/ROUTE-PATHS/playGroundRoute";
export default function AllRoutes() {
  const is_admin = useAuthRole(0);
  const is_sub_admin = useAuthRole(1);
  const is_data_analyzer = useAuthRole(4);
  const isVendor = useAuthRole(2);
  const test_user = useAuthRole(3);
  // Define routes based on user roles
  const routes = [
    AuthRoutes, // Always show authentication routes
    PublicRoutes, // Always show public routes
    PlaygroundRoute,
    ...(is_data_analyzer ? [dataAnalyzeUserRoutes] : []),
    ...(is_admin || is_sub_admin ? [AdminRoutes()] : []),
    ...(isVendor ? [PartnerRoutes] : []),
    {
      path: "*",
      element: <Navigate to="/auth/login" replace />, // Redirect to login or home screen
    },
  ];
  // Return routes using useRoutes hook
  return useRoutes(routes);
  // return useRoutes([
  //   // !(is_admin || is_sub_admin || isVendor || test_user) && AuthRoutes,
  //   AuthRoutes,
  //   PublicRoutes,
  //   (is_admin || is_sub_admin) && adminRoutes, // Show admin routes if user is an admin
  //   isVendor && PartnerRoutes, // Show vendor routes if user is a vendor
  // ]);
}
