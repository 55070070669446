import axios from "axios";
import config from "../config";
const axiosInstance = axios.create({
  baseURL: config.apiBaseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.request.use(
  async (config) => {
    // Add logic to include the access token in the request headers
    let accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the error response is due to an expired token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Get a new access token using the refresh token
      let refresh_old_Token = localStorage.getItem("refreshToken");

      if (refresh_old_Token) {
        try {
          const response = await axios.post(
            `${config.apiBaseURL}/v1/auth/token/refresh/`,
            { refresh: refresh_old_Token }
          );

          const { access, refresh } = response.data;

          // Update local storage with the new tokens
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);

          // Retry the original request with the new access token
          originalRequest.headers["Authorization"] = `Bearer ${access}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Handle refresh token failure, e.g., redirect to login page
          console.error("Failed to refresh token:", refreshError);
          // Redirect to the login page or handle the error appropriately
        }
      }
    }
    return Promise.reject(error);
  }
);
export const NormalAxiosInstance = axios.create({
  baseURL: config.apiBaseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
