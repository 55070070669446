import React from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionActions as MuiAccordionActions,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

// Styled components for Accordion, AccordionSummary, and AccordionDetails
export const Accordion = styled((props) => <MuiAccordion {...props} />)(
  ({ theme }) => ({
    // paddingTop: "5px",
  })
);

export const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme}) => ({
        borderBottom: "1px solid #ddd",
      // paddingTop:"5px"
  })
);

export const AccordionDetails = styled((props) => (
  <MuiAccordionDetails {...props} />
))(({ theme }) => ({
  backgroundColor: "#fafafa",
  padding:'10px 30px'
}));
export const AccordionActions = styled((props) => <MuiAccordionActions {...props} />)(
  ({ theme }) => ({
    backgroundColor: "#fafafa",
    borderTop: "1px solid lightgrey",
    padding:'0px 30px 20px 30px',
  })
);

// Additional styled typography components
export const StyledHeadTypo = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#000",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#000",
  marginBottom: theme.spacing(2),
}));

export const StyledSubTypo = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#003b72",
  marginBottom: theme.spacing(2),
}));
