import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party

import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
// import MainCard from "ui-component/cards/MainCard";
// import Transitions from "ui-component/extended/Transitions";

// assets
import { IconFilterMinus } from "@tabler/icons-react";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  ClickAwayListener,
  Grid,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Chip,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import UploadStatusList from "./UploadStatusList";
import { StyledTypography } from "src/components/AccordionStyles";
import { StyledSubText, StyledText } from "src/components/TextSubText";

// ==============================|| NOTIFICATION ||============================== //

const ApiTotalCounts = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = (event) => {
    if (event?.target.value) setValue(event?.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <ButtonBase sx={{ borderRadius: "12px" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <IconFilterMinus stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
        <Grid className="api-calls-count-div">
          <StyledTypography className="total-calls" variant="p">
            Total Calls : <StyledSubText variant="span"> 3000</StyledSubText>
          </StyledTypography>
          <StyledTypography className="avail-calls" variant="p">
            Available Calls :{" "}
            <StyledSubText variant="span"> 1000</StyledSubText>
          </StyledTypography>

          {/* if pay as u go pack show this  else show above*/}

          {/* <StyledTypography className="total-calls" variant="p">
            Remaining Days : <StyledSubText variant="span"> 10</StyledSubText>
          </StyledTypography>
          <StyledTypography className="avail-calls" variant="p">
            Remaining Calls : <StyledSubText variant="span"> 300</StyledSubText>
          </StyledTypography> */}
        </Grid>
      </Box>
      <Popover
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition={true ? "true" : undefined}
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Card
              border={false ? undefined : "border-value"}
              elevation={16}
              content={false ? undefined : "content-value"}
              shadow={theme.shadows[16]}
            >
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ pt: 2, px: 2 }}
                  >
                    <Grid item>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="subtitle1"
                          fontWeight="fontWeightBold"
                        >
                          Api Calls Counts
                        </Typography>
                        <Chip
                          size="small"
                          label="3000"
                          sx={{
                            color: theme.palette.background.default,
                            bgcolor: theme.palette.warning.dark,
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 205px)",
                      overflowX: "hidden",
                    }}
                  >
                    <List>
                      <ListItem>
                        {" "}
                        <StyledTypography variant="p">
                          Free package :{" "}
                          <StyledSubText variant="span"> 100</StyledSubText>
                        </StyledTypography>
                      </ListItem>
                      <ListItem>
                        {" "}
                        <StyledTypography variant="p">
                          Package1 :{" "}
                          <StyledSubText variant="span"> 100</StyledSubText>
                        </StyledTypography>
                      </ListItem>
                      <ListItem>
                        {" "}
                        <StyledTypography variant="p">
                          Additional Pack :{" "}
                          <StyledSubText variant="span"> 300</StyledSubText>
                        </StyledTypography>
                      </ListItem>

                      <Divider />
                      <ListItem>
                        {" "}
                        <StyledTypography className="" variant="p">
                          Total Calls :{" "}
                          <StyledSubText variant="span"> 410</StyledSubText>
                        </StyledTypography>
                      </ListItem>
                      <Divider />

                      <ListItem>
                        {" "}
                        <StyledTypography className="" variant="p">
                          Remaining Calls :{" "}
                          <StyledSubText variant="span"> 300</StyledSubText>
                        </StyledTypography>
                      </ListItem>
                    </List>

                    <List>
                      <ListItem>
                        {" "}
                        <StyledTypography variant="p">
                          Max Days :{" "}
                          <StyledSubText variant="span"> 15</StyledSubText>
                        </StyledTypography>
                      </ListItem>
                      <ListItem>
                        <StyledTypography variant="p">
                          Max Calls :{" "}
                          <StyledSubText variant="span"> 150 </StyledSubText>
                        </StyledTypography>
                      </ListItem>
                      <ListItem>
                        {" "}
                        <StyledTypography className="" variant="p">
                          Remaining Days :{" "}
                          <StyledSubText variant="span"> 10</StyledSubText>
                        </StyledTypography>
                      </ListItem>
                      <Divider />

                      <ListItem>
                        {" "}
                        <StyledTypography className="" variant="p">
                          Remaining Calls :{" "}
                          <StyledSubText variant="span"> 100</StyledSubText>
                        </StyledTypography>
                      </ListItem>
                    </List>
                  </PerfectScrollbar>
                </Grid>
              </Grid>
            </Card>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  );
};

export default ApiTotalCounts;
