import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Toolbar from "@mui/material/Toolbar";
import AdbIcon from "@mui/icons-material/Adb";
import { Button, Link, useScrollTrigger } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { IconMenuDeep, IconX } from "@tabler/icons-react";

const drawerWidth = 240;
const navItems = [
  {
    menu: "Home",
    link: "/",
  },
  {
    menu: "AboutUs",
    link: "/about-us",
  },
  {
    menu: "Contact",
    link: "/contact-us",
  },
];

const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleListItemClick = (e) => {
    e.stopPropagation(); // Prevents closing the drawer when clicking on a ListItem
    handleDrawerToggle();
  };
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <Box sx={{ display: "flex" }} className="webHeader">
      <CssBaseline />
      <AppBar component="nav" className={trigger ? "navbar-fixed" : "navbar"}>
        <Toolbar className="container toolBar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            {mobileOpen ? <IconX /> : <IconMenuDeep />}
          </IconButton>

          <AdbIcon sx={{ display: { xs: "block", md: "flex" }, mr: 1 }} />

          <Link
            variant="h4"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              flexGrow: 1,
            }}
          >
            StarVizz
          </Link>
          <Box
            className="navMenu"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {navItems.map((item) => (
              <Link
                component={RouterLink}
                key={item}
                className="navLink"
                to={item.link}
              >
                {item.menu}
              </Link>
            ))}
          </Box>
          <Link
            component={RouterLink}
            sx={{ color: "#000", display: { xs: "none", sm: "block" } }}
            className="navLink"
            to="/auth/login"
          >
            {/* button background: #00bcd4  onhover : #00838f */}
            <Button variant="contained" sx={{ background: "#00bcd4" }}>
              Login
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          className="mobileNav"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
            <Divider />
            <List>
              {navItems.map((item) => (
                <ListItem key={item} disablePadding>
                  <ListItemButton
                    sx={{ textAlign: "center" }}
                    // onClick={handleListItemClick}
                  >
                    <Link
                      component={RouterLink}
                      key={item}
                      className="navLink"
                      to={item.link}
                      onClick={handleListItemClick}
                    >
                      {item.menu}
                    </Link>
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem>
                <Link
                  component={RouterLink}
                  sx={{ color: "#000", display: { xs: "block", sm: "none" } }}
                  className="navLink"
                  to="/auth/login"
                >
                  Login
                </Link>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </nav>
    </Box>
  );
};

export default Header;
