// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup/NavGroup";

import adminDashboard from "src/menu-items/AdminDashboard";
import VenderDashboard from "src/menu-items/VenderDashboard";
import Analyser_dashboard_menu from "src/menu-items/Analyser_dashboard_menu";
// import skills_menu from "src/menu-items/skills_menu";
import clients from "src/menu-items/clients";
import SubAdmin from "src/menu-items/subAdmin";
import packages from "src/menu-items/packages";
import VenderPlayGroundMenu from "src/menu-items/vender-playground-menu";
import vender_packages_menu from "src/menu-items/vender_packages_menu";
import vender_purchase_history from "src/menu-items/vender_purchase_history";
import Documentation_menu from "src/menu-items/Documentation_menu";
import sales_history from "src/menu-items/sales_history";
import createSkillsMenu from "src/menu-items/skills_menu";
import NavItem from "./NavItem/NavItem";
import NavCollapse from "./NavCollapse/NavCollapse";
import defaultMenu from "src/menu-items/default_menu";
// import categories_menu from "src/menu-items/categories_menu";
// import currencies_menu from "src/menu-items/currencies_menu";
// import setup_menu from "src/menu-items/setup_menu";
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ AuthRole }) => {
  let menuItems = [];
  // Generate skills menu based on user role
  const skillsMenu = createSkillsMenu(AuthRole);

  // admin and sub admin
  if (AuthRole === 0 || AuthRole === 1) {
    menuItems = [
      adminDashboard,
      skillsMenu,
      packages,

      clients,
      sales_history,

      // currencies_menu,
      // categories_menu,
      // setup_menu,
    ];
    if (AuthRole === 0) {
      // Append SubAdmin Menu for admin role
      menuItems.push(SubAdmin);
    }
    menuItems.push(defaultMenu);
  } else if (AuthRole === 2) {
    menuItems = [
      VenderDashboard,
      VenderPlayGroundMenu,
      vender_packages_menu,
      vender_purchase_history,
      Documentation_menu,
    ];
  }
  if (AuthRole === 4) {
    menuItems = [Analyser_dashboard_menu, skillsMenu];
  }

  const navItems = menuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={1} />;
      case "item":
        return <NavItem key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
