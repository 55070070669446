import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    () => localStorage.getItem("accessToken") ?? null
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken") ?? null
  );
  const [user, setUser] = useState(localStorage.getItem("user") ?? null);

  const login = (userData, tokens) => {
    setUser(userData);
    setAccessToken(tokens.access);
    setRefreshToken(tokens.refresh);
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("accessToken", tokens.access);
    localStorage.setItem("refreshToken", tokens.refresh);
  };
  // Function to handle logout
  const logout = () => {
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    // Clear user data and tokens from local storage
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };
  // Value object to provide to the context
  const value = {
    user,
    accessToken,
    refreshToken,
    login,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
