import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "src/website/asset/styles/websiteStyles.css";
import "src/website/asset/styles/responsiveStyles.css";
import BackToTopButton from "./BacktoTop";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main className=" main">{children}</main>
      <Footer />
      <BackToTopButton />
    </div>
  );
};

export default Layout;
