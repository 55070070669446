import React from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import { Box, Typography } from "@mui/material";
const getColor = (value) => {
  if (value <= 50) {
    return "primary"; // Blue
  } else if (value <= 75) {
    return "success"; // Green
  } else if (value < 100) {
    return "warning"; // Warning color
  } else {
    return "error"; // Red
  }
};
const LinearProgressWithLabel = (props) => {
  
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          value={props.value}
          color={getColor(props.value)}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
export default LinearProgressWithLabel;
